.home__social {
    display: flex;
    gap: 10px; /* Add space between icons */
  }
  
  .social-icon {
    height: 15px; /* Default size */
    margin: 0 5px; /* Add spacing */
    transition: transform 0.3s ease; /* Add hover effect */
  }
  
  .social-icon:hover {
    transform: scale(1.2); /* Slightly enlarge on hover */
  }
  
  /* Larger screen sizes */
  @media (min-width: 768px) {
    .social-icon {
      height: 25px; /* Increase size for larger screens */
    }
  }
  
  /* Very large screens */
  @media (min-width: 1200px) {
    .social-icon {
      height: 30px; /* Further increase size */
    }
  }
  
  /* Smaller screen sizes */
  @media (max-width: 480px) {
    .social-icon {
      height: 12px; /* Reduce size for small screens */
    }
  }
  