


.btn
{
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    
}
.home__content
{
    
   
    
    align-items: center;
}

.home__social {
    position: absolute;
    top: 28.5%;
    left: 94%;
    transform: translateX(-50%);
    display: flex; /* Set display to flex */
    flex-direction: column; /* Set flex direction to row */
    align-items: center; /* Align items in the center vertically */
    
    padding: 0.1rem 0.2rem;
    justify-content: center;
    gap: 15px;
    
}




.home__social-icon:hover{
    color: var((--title-color-dark));
}

.home__title
{
 
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}



.home__hand
{
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}


.home__subtitle
{
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}



.home__description
{
    
    margin-bottom: var(--mb-3);

    text-align: justify;
    text-align-last: left;
    text-indent: 20px; /* Adjust the value as needed */
    max-width: 50%; /* Set the maximum width of the text container */
    margin: 0 auto; /* Center the text container horizontally */
    padding-bottom: 70px;
    
    
}




.home__img
{
    background: url(../../assets/profile1.jpg);
    
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    
    order: 1;
    justify-self: center;
    width: 100%;
    height: 1200px;
    margin-top: -6px;
    
}
.home__data
{
    order: 2;
    justify-self: center;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: var(--background-dark-color-2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

@keyframes profile__animate {
    0%
    {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50%
    {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100%
    {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.home__scroll
{
    margin-left: 9.25rem;
}

.wheel
{
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0%
    {
        transform: translateY(0);
    }
    30%
    {
        transform: translateY(3.75rem);
    }
}

.home__scroll-name
{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow
{
    font-size: 1.25rem;
    color: var(--title-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */


@media screen and (max-width: 992px) {
    .home__content
    {
        grid-template-columns: 100px repeat(2, 1fr);
       
    }
    .home__hand
    {
        width: 26px;
        height: 26px;
    }
    .home__subtitle
    {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }
    .home__subtitle::before
    {
        width: 42px;
        top: 0.8rem;
    }
    .home__description
    {
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }
    .home__img
    {
        width: 250px;
        height: 250px;
        
    }
    .home__scroll
    {
        margin-left: 7.5rem;
    }
    .apoena {
        margin-left: 0%;
    }
 }

  
  /* For medium devices */

  
  @media screen and (max-width: 768px) {
   .home__content{
    grid-template-columns: 0.5fr 3fr;
    
   }
   .apoena {
    display: block ;
    
}
   .home__img
   {
    order: initial;
    justify-self: initial;
   }
   .home__data
   {
    grid-column: 1/3;
    padding-top: 0px;
    
   }
   .home__img{
    
    width: 100%;
    height: 300px;
   }
   .home__scroll
   {
    display: none;
   }
   .home__title
   {
    text-align: center;
    
   }
   .home__subtitle::before
    {
        width: 0;
        top: 0;
    }
    .home__subtitle
    {
        padding-left: 0;
        text-align: center;
    }
    .home__description_container {
        max-width: 98%;
        margin: 0 auto;
    }

    .home__description {
        text-align: justify;
        text-align-last: left;
        text-indent: 20px; /* Adjust the value as needed */
        max-width: 97%; /* Set the maximum width of the text container */
        margin: 0 auto; /* Center the text container horizontally */
        padding-bottom: 0px;
        word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
        
        
        
        
    }
    
    
    
    
    
    .home__description::after {
        content: "";
        display: inline-block;
        width: 100%;
    }
   .apoena{
    margin-left: 0%;
}


   
  }
  
   
  /* For small devices */
  @media screen and (max-width: 350px) {
    .home__img
    {
        width: 100%;
        height: 230px;
        margin-right: 20px;
    }
    .home__hand{
        width: 22px;
        height: 22px;
    }
    .apoena{
        margin-left: 0%;
    }
    .home__container 
    {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    
  }

  
  